import { createSlice } from "@reduxjs/toolkit";
import { TW_QUOTE_REDUCERS } from "./TWQuoteReducer";
import { TW_REDUCERS } from "./TWReducer";
import { FORMAT_DD_MM_YYYY } from "../../../SupportingFiles/HelpingFunction";
import { TTWSlice } from "../../../types/TTWSlice";

const intitialState: TTWSlice = {
  REDIRECTED: false,
  PLAN_TYPE: "",
  SORT_BY: "Low to high",
  IDV_SELECTED_OPTION: "Best Deal",
  DROPDOWN_DATA: {
    POLICY_TYPE: [
      { label: "Bundle Policy", value: "Bundle Policy" },
      { label: "SAOD", value: "SAOD" },
      { label: "Comprehensive", value: "Comprehensive" },
      { label: "Third Party", value: "Third Party" },
    ],
    CLAIM_STATUS: [
      { label: "Yes", value: "Yes" },
      { label: "No", value: "No" },
    ],
    POPULAR_MAKE_LIST: [
      { value: "TVS", label: "TVS" },
      { value: "Hero", label: "Hero" },
      { value: "honda", label: "Honda" },
      { value: "bajaj", label: "Bajaj" },
      { value: "suzuki", label: "Suzuki" },
      { value: "royalenfield", label: "Royal Enfield" },
    ],
    PA_OWNER_COVER_DATA: [
      {
        label: "Owner does not have valid driving license",
        value: "Owner does not have valid driving license",
      },
      {
        label: "Already having PA/SCPA cover",
        value: "Already having PA/SCPA cover",
      },
    ],
    PLAN_TYPE: [
      { label: "Comprehensive", value: "Comprehensive" },
      { label: "Third Party", value: "Third Party" },
    ],
    SORT_BY: [
      { label: "LOW_TO_HIGH", value: "Low to high" },
      { label: "HIGH_TO_LOW", value: "High to low" },
    ],
    MAKE_MODEL_LIST: [],
    POPULAR_MAKE: ["Bajaj", "Honda", "TVS", "Hero", "RoyalEnfield", "KTM"],
    RTO_LIST: [],
    NCB: [
      { label: "0%", value: "0" },
      { label: "20%", value: "20" },
      { label: "25%", value: "25" },
      { label: "35%", value: "35" },
      { label: "45%", value: "45" },
      { label: "50%", value: "50" },
    ],
    FUEL_TYPE_LIST: [],
    MAKE_LIST: [],
    MODEL_LIST: [],
    PREVIOUS_INSURER_LIST: [],
    VARIANT_LIST: [],
    VEHICLE_ON_LOAN: [],
  },
  ADD_FORM: {
    page_status: 1,
    term_cond: true,
    name: { value: "", warning: false },
    business_type: "Rollover",
    invoice_date: {
      value: FORMAT_DD_MM_YYYY(`${new Date()}`),
      warning: false,
    },
    claimed: "No",
    fuel_type: { value: "", warning: false },
    make: { value: "", warning: false },
    mobile: { value: "", warning: false },
    make_model: { value: "", warning: false },
    model: { value: "", warning: false },
    email: { value: "", warning: false },
    prev_ncb: "0",
    previous_insurer: { value: "", warning: false },
    reg_date: { value: null, warning: false },
    reg_no: { value: "", warning: false },
    variant_cc: { value: "", warning: false },
    rto: { value: "", warning: false },
    policy_expiry_date: { value: null, warning: false },
  },
  ADD_FORM_RESPONSE: {
    quote_no: "",
    module: "TwInsurance",
    insurance_type: "Tw Insurance",
    name: "",
    email: "",
    mobile: "8708430006",
    city: "",
    state: "",
    utm_source: "",
    utm_medium: "",
    utm_medium_m: "",
    utm_medium_d: "",
    utm_campaign: "",
    utm_keyword: "",
    status: 1,
    device: "",
    ip_address: "",
    lead_for: 2,
    make_model: "",
    variant_cc: "",
    fuel_type: "",
    business_type: "Rollover",
    tp_policy_start_date: "",
    policy_expiry_date: "",
    new_policy_date: "",
    manufacture_date: "",
    registration_date: "",
    registration_no: "",
    actual_reg_no: "",
    rto_code: "",
    rto_location: "",
    previous_insurer: "",
    tp_previous_insurer: "",
    previous_ncb: "20",
    new_ncb: "25",
    claim_made: "No",
    electric_accessory: 0,
    non_electric_accessory: 0,
    passernger_cover: 0,
    cng_kit: 0,
    deductible: 0,
    driving_exp: 0,
    avg_monthly_milege: 0,
    anti_theft_device: "No",
    automobile_association: "No",
    ambulance_charges_cover: 0,
    consumables_cover: 0,
    depreciation_cover: 0,
    hydrostatic_lock_cover: 0,
    medical_expenses: 0,
    invoice_cover: 0,
    road_side_assistance: 0,
    engine_gear_box_protection: 0,
    no_claim_bonus_same_slab: 0,
    driver_cover: 0,
    pa_owner_driver: 0,
    reason_for_exemption_pa: 1,
    vehicle_owned: "Individual",
    owner_occupation: "",
    owner_dob: "",
    bundled_policy: 1,
    idv: 0,
    tw_master_data_id: 438,
    make_id: 11,
    model_id: 1,
    variant_id: 2,
    api_unique_id: "11.1.2",
    make: "",
    model: "",
    variant: "",
    cubic_capacity: "109",
    seating_capacity: "2",
    api_owner_name: "Hjw Sksk",
    api_owner_gender: "M",
    api_owner_email: "test@test.com",
    api_owner_dob: "2004-08-18",
    api_registration_date: "2016-11-01",
    api_chasis_no: "IG7T7T7FF6FYFYFYF",
    api_engine_no: "7G73G7G272GS7SG2S27GS7",
    api_vehicle_class: "",
    api_maker: "",
    api_rto: "",
    api_address_p_house: "Hg Bbss",
    api_address_p_pincode: "247001",
    api_address_p_area: "Ushs Sge",
    api_address_p_district: "",
    api_address_p_city: "Saharanpur",
    api_address_p_state: "Uttar Pradesh",
    requestTime: 1687447641,
    header_code_id: 2,
    header_code_desc: "Quotation Taken",
  },
  PRODUCT_LIST: [],
  SELECTED_QUOTE_DATA: {
    buy_online_code: "",
    company_code: "",
    id: 0,
    loader: false,
    logo: "",
    product_code: "",
    product_type: "Comprehensive",
    quotationDetail: {
      premiumDetails: {
        withAddonnBasicOD: 0,
        withoutAddonnBasicOD: 0,
        withAddonNetPremium: 0,
        withoutAddonNetPremium: 0,
        exshowroomPrice: "",
        minimumIDV: 0,
        maximumIDV: 0,
        freeRoadSideAssistance: 0,
        product_code: "",
        company_code: "",
        includedPAOwnerDriver: 0,
        basicOD: 0,
        basicTP: 0,
        depreciationCover: 0,
        driverCover: 0,
        IDV: 0,
        ncbDiscount: 0,
        netPremium: 0,
        odDiscount: 0,
        paOwnerDriver: 0,
        roadSideAssistance: 0,
        secondYear: null,
        serviceTax: 0,
        thirdYear: null,
      },
      CompanyDetails: {
        company_code: "",
        name: "",
        short_desc: "",
        logo: "",
        claim_ratio: "",
      },
      cashlessGarageLists: [],
      QuoteNo: "",
      productDetailLists: "",
      productDetails: {
        id: 0,
        product_name: "",
        brochure: "",
        product_code: "",
        quote_action: "",
        rate_calc_method: "",
        proposal_method: "",
        tax_calc_method: "",
        discount_calc_method: "",
        product_desc: "",
        buy_online_code: "",
        policy_covers: "",
      },
    },
  },
  ADDON_STATUS: {
    zero_depreciation: { label: "Zero Depreciation", value: false },
    pa_owner_cover: { label: "Personal Accident Cover", value: false },
    rsa: { label: "24x7 Roadside Assistance", value: false },
    driver_cover: { label: "Driver Cover", value: false },
  },
  OWNER_DETAILS: {
    name: { value: "", warning: false },
    mobile: { value: "", warning: false },
    email: { value: "", warning: false },
    dob: { value: null, warning: false },
    gender: { value: "", warning: false },
    maritalStatus: { value: "", warning: false },
    pacover: {
      value: "Owner does not have valid driving license",
      warning: false,
    },
    GST: { value: "", warning: false },
    validPuc: true,
    vehicleRegistered: false,
    isPolicyComprehensive: true,
    includePAOwner: false,
  },
  CKYC_DETAILS: {
    propFullName: "",
    ProposerDob: "",
    docID: "",
    cusdtomertype: "",
    verificationDoc: "",
    gender: "",
  },
  NOMINEE_DETAILS: {
    fullName: { value: "", warning: false },
    dob: { value: "", warning: false },
    relationship: { value: "", warning: false },
  },
  ADDRESS_DETAILS: {
    pincode: { value: "", warning: false },
    addressLineOne: { value: "", warning: false },
    addressLineTwo: { value: "", warning: false },
    city: "",
    state: "",
    mailingPinCode: { value: "", warning: false },
    mailingCity: "",
    mailingState: "",
    mailingAddressLineOne: { value: "", warning: false },
    mailingAddressLineTwo: { value: "", warning: false },
    isMailingAddressSame: true,
  },
  VEHICLE_DETAILS: {
    PAN: { value: "", warning: false },
    chasis_no: { value: "", warning: false },
    engine_no: { value: "", warning: false },
    bank_name: { value: "", warning: false },
    city: { value: "", warning: false },
    mfg_year: { value: "", warning: false },
    policy_exp_date: { value: "", warning: false },
    prev_insurer: { value: "", warning: false },
    prev_policy_no: { value: "", warning: false },
    reg_date: { value: "", warning: false },
    tp_policy: { value: "", warning: false },
    tp_insurer: { value: "", warning: false },
    tp_policy_start: { value: "", warning: false },
    is_vehicle_on_loan: false,
    invoice_date: { value: "", warning: false },
  },
  IDV_VALUE: {
    value: "",
    warning: false,
  },
  IDVApply_Status: false,
  QUOTE_LOADER: true,
  PAGE_STATUS: false,
  ALL_QUOTES_DATA: [],
  Home_Loader: false,

  TW_PREFILLED_PAGE_STATUS: 0,
  OPEN_TW_PREFILLED_DATA: false,
};

export const TWSlice = createSlice({
  name: "TWO_WHEELER",
  initialState: intitialState,
  reducers: { ...TW_REDUCERS, ...TW_QUOTE_REDUCERS },
});
