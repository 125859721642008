import SelectDropdown from "@/Component/InputFields/SelectDropdown/SelectDropdown";
import { COMMON_ROUTES } from "@/Router/Path/CommonRoutes";
import { Box, Button, Dialog, DialogContent } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { COMMON_SERVICES } from "../../../../Services/Common/CommonService";
import { COLORS } from "../../../../SupportingFiles/colors";
import {
  isEmpty,
  validateMobileNumber,
} from "../../../../SupportingFiles/HelpingFunction";
import { TFieldData } from "../../../../types/Common/TFieldData";
import JDSButtons from "../../../InputFields/JDSButtons/JDSButtons";
import TextInputField from "../../../InputFields/TextInputField/TextInputField";
import "../../../../SCSS/ModalPopup.scss";

const ExitIntentPopup = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: Function;
}) => {
  // const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [thank, setThank] = useState(false);
  const [load, setLoad] = useState(false);
  const [mobile, setMobile] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");
  const [isDropdownVisible, setIsDropdownVisible] = useState(true);
  const [callNowStep, setCallNowStep] = useState<"1" | "2">("1");
  const productTypes = [
    { label: "Health", value: "/Page/Desktop/HealthProductPage" },
    { label: "Health", value: "/Page/Desktop/LandingHealthProductPage" },
    { label: "Car", value: "/Page/Desktop/CarProductPage" },
    { label: "Car", value: "/Page/Desktop/LandingCarProductPage" },
    { label: "Two Wheeler", value: "/Page/Desktop/TWProductPage" },
    { label: "Two Wheeler", value: "Page/Desktop/LandingTWProductPage" },
    { label: "Term", value: "/Page/Desktop/TermProductPage" },
    { label: "Term", value: "/Page/Desktop/LandingTermProductPage" },
    { label: "Health", value: "/Page/Desktop/PreQuoteForms/HealthFormPDP" },
    { label: "Car", value: "/Page/Desktop/PreQuoteForms/CarFormPDP" },
    { label: "Two Wheeler", value: "/Page/Desktop/PreQuoteForms/BikeFormPDP" },
    { label: "Term", value: "/Page/Desktop/PreQuoteForms/TermFormPDP" },
  ];
  const showProductTypes = [
    { label: "Health", value: "/Page/Desktop/HealthProductPage" },
    { label: "Car", value: "/Page/Desktop/CarProductPage" },
    { label: "Two Wheeler", value: "/Page/Desktop/TWProductPage" },
    { label: "Term", value: "/Page/Desktop/TermProductPage" },
  ];

  const PRODUCT_ROUTES: string[] = [
    COMMON_ROUTES.PRODUCT_HEALTH,
    COMMON_ROUTES.LANDING_PRODUCT_HEALTH,
    COMMON_ROUTES.LANDING_PRODUCT_CAR,
    COMMON_ROUTES.PRODUCT_CAR,
    COMMON_ROUTES.LANDING_PRODUCT_TW,
    COMMON_ROUTES.PRODUCT_TW,
    COMMON_ROUTES.LANDING_PRODUCT_TERM,
    COMMON_ROUTES.TERM_FORM_PDP,
    COMMON_ROUTES.HEALTH_FORM_PDP,
    COMMON_ROUTES.CAR_FORM_PDP,
    COMMON_ROUTES.BIKE_FORM_PDP,
  ];



  const INSURANCE_TYPE_MAP: Record<string, string> = {
    [COMMON_ROUTES.PRODUCT_HEALTH]: "Health Insurance",
    [COMMON_ROUTES.LANDING_PRODUCT_HEALTH]: "Health Insurance",
    [COMMON_ROUTES.HEALTH_FORM_PDP]: "Health Insurance",

    [COMMON_ROUTES.PRODUCT_CAR]: "Car Insurance",
    [COMMON_ROUTES.LANDING_PRODUCT_CAR]: "Car Insurance",
    [COMMON_ROUTES.CAR_FORM_PDP]: "Car Insurance",

    [COMMON_ROUTES.PRODUCT_TW]: "Tw Insurance",
    [COMMON_ROUTES.LANDING_PRODUCT_TW]: "Tw Insurance",
    [COMMON_ROUTES.BIKE_FORM_PDP]: "Tw Insurance",

    [COMMON_ROUTES.PRODUCT_TERM]: "Term Insurance",
    [COMMON_ROUTES.LANDING_PRODUCT_TERM]: "Term Insurance",
    [COMMON_ROUTES.TERM_FORM_PDP]: "Term Insurance",
  };


  useEffect(() => {
    if (typeof window === "undefined") return; // Ensure it runs only in the browser

    const path: string = window.location.pathname; // ✅ Explicitly typed as string
    console.log("path", path);

    // Check if the path is in the predefined product routes
    if (PRODUCT_ROUTES.includes(path)) {
      console.log("Special product page detected. Changing flow...");
      setIsDropdownVisible(false);
      setSelectedProduct(path);
      return;
    }

    // Default behavior for other pages
    const matchingProduct = productTypes.find(
      (product) => product.value === path
    );
    if (matchingProduct) {
      setSelectedProduct(matchingProduct.value);
      setIsDropdownVisible(false);
    } else {
      setIsDropdownVisible(true);
    }
  }, [open]);

  const [shareURLData, setShareURLData] = useState<{
    mobile: TFieldData;
    name: TFieldData;
    product?: { warning: boolean };
  }>({
    mobile: { value: "", warning: false },
    name: { value: "", warning: false },
  });

  const handleDetails = (attrName: any, value: any) => {
    if (attrName === "mobile") {
      setShareURLData((prev) => ({
        ...prev,
        mobile: { value: value, warning: !validateMobileNumber(value) },
      }));
    }
    if (attrName === "name") {
      setShareURLData((prev) => ({
        ...prev,
        name: { value: value, warning: isEmpty(value) },
      }));
    }
  };

  const handleProductChange = (value: string, attrName: string) => {
    setSelectedProduct(attrName);

    setShareURLData((prev) => ({
      ...prev,
      product: { warning: false },
    }));
  };

  const EVENT_TRACKER = () => {
    setLoad(true);
    let Mdata = { ...shareURLData };

    if (!isEmpty(Mdata.name.value)) {
      Mdata = {
        ...Mdata,
        name: { ...Mdata.name, warning: false },
      };
    } else {
      Mdata = {
        ...Mdata,
        name: { ...Mdata.name, warning: true },
      };
    }

    if (!isEmpty(Mdata.mobile.value)) {
      Mdata = {
        ...Mdata,
        mobile: {
          ...Mdata.mobile,
          warning: !validateMobileNumber(Mdata.mobile.value),
        },
      };
    } else {
      Mdata = {
        ...Mdata,
        mobile: {
          ...Mdata.mobile,
          warning: true,
        },
      };
    }

    if (isDropdownVisible && isEmpty(selectedProduct)) {
      Mdata = {
        ...Mdata,
        product: { warning: true },
      };
    } else {
      Mdata = {
        ...Mdata,
        product: { warning: false },
      };
    }
    setShareURLData(Mdata);
    const hasError =
      Mdata.name.warning ||
      Mdata.mobile.warning ||
      (isDropdownVisible && isEmpty(selectedProduct));

    if (hasError) {
      toast.error("Please fill mandatory fields.");
      setLoad(false);
      return;
    }

    const onSuccess = (res: any) => {
      setLoad(false);
      setCallNowStep("2");
      setShareURLData({
        mobile: { value: "", warning: false },
        name: { value: "", warning: false },
        product: { warning: false },
      });
      // setIsFormSubmitted(true);
      sessionStorage.setItem("exitPopupShown", "true");
      // setThank(true);
    };

    const onError = (err: any) => {
      console.log(err);
      setOpen(false);
      setLoad(false);
      setShareURLData({
        mobile: { value: "", warning: false },
        name: { value: "", warning: false },
      });
    };
    setMobile(Mdata?.mobile?.value);
    const data = {
      name: Mdata.name.value,
      mobile: Mdata.mobile.value,
      ctc_status: "3",
      insurance_type: INSURANCE_TYPE_MAP[selectedProduct],
    };

    COMMON_SERVICES.CLICK_TO_CALL(onSuccess, onError, data);
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        // setIsFormSubmitted(false);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setThank(false);
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [thank]);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {}}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        className="modal-scrollable"
      >
        <DialogContent
          dividers={false}
          className="modal-content xsWidth scheduleCallPopup px-6"
        >
          <Grid className="topbar" container alignItems="center">
            <Grid size={12}>
              <Box
                display={"flex"}
                alignItems={"end"}
                justifyContent={"space-between"}
                sx={{ float: "inline-end" }}
              >
                <Button
                  disableRipple
                  className="closePopup"
                  data-tagname="close"
                  onClick={() => {
                    setOpen(false);
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          {callNowStep === "1" ? (
            <Grid
              container
              className="row"
              columnSpacing={2}
              alignItems="flex-start"
            >
              <Grid size={12}>
                <Box>
                  <h3
                    style={{
                      fontWeight: "900",
                      textAlign: "left",
                      marginBottom: "16px",
                    }}
                  >
                    Happy to help
                  </h3>
                  <p className="mb-4" style={{ color: COLORS.lightgrey }}>
                    Could not find what you are looking for? If you need
                    assistance with your insurance needs, we&#x27;re here for
                    you.
                  </p>

                  <Grid size={12}>
                    <TextInputField
                      class_name="inputField mb-6"
                      title={"Enter name"}
                      value={shareURLData.name.value}
                      attrName={"name"}
                      value_update={handleDetails}
                      error_message={
                        isEmpty(shareURLData.name.value) ? "Enter name" : ""
                      }
                      warn_status={shareURLData.name.warning}
                      validation_type="NAME"
                    />
                  </Grid>
                  <Box className="mobileNumber">
                    <Box
                      position="absolute"
                      left="12px"
                      top="31px"
                      zIndex="10"
                      fontSize="16px"
                      fontWeight="bold"
                      sx={{ color: COLORS.lightgrey }}
                    >
                      +91
                    </Box>
                    <TextInputField
                      class_name="inputField"
                      title={"Enter your mobile number"}
                      value={shareURLData.mobile.value}
                      attrName={"mobile"}
                      value_update={handleDetails}
                      error_message={
                        !isEmpty(shareURLData.mobile.value)
                          ? "Enter Valid Mobile Number"
                          : "Enter Mobile Number"
                      }
                      warn_status={shareURLData.mobile.warning}
                      validation_type="NUMBER"
                      max_length={10}
                    />
                  </Box>

                  {isDropdownVisible && (
                    <Grid size={12} className="mt-5">
                      <SelectDropdown
                        title="Product I want"
                        data={showProductTypes}
                        attrName="selectedProduct"
                        error_message="Please select a product"
                        value={selectedProduct}
                        value_update={handleProductChange}
                        warn_status={shareURLData.product?.warning || false}
                      />
                    </Grid>
                  )}

                  <Grid size={12} className="mt-8" textAlign={"center"}>
                    <JDSButtons
                      text={"Request a call back"}
                      variant={"text"}
                      className={"primaryBtns large"}
                      loader={load}
                      disabled={load}
                      onClick={() => {
                        EVENT_TRACKER();
                      }}
                    />
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          ) : (
            <Grid container columnSpacing={2}>
              <Grid size={12}>
                <Box>
                  <h3
                    style={{
                      fontWeight: "900",
                      textAlign: "left",
                      marginBottom: "16px",
                    }}
                  >
                    Thank you for your interest.
                  </h3>
                  <p className="mb-4">
                    We will get in touch with you on{" "}
                    <span style={{ color: COLORS.primary }}>{mobile}</span>
                  </p>
                </Box>
              </Grid>
            </Grid>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ExitIntentPopup;
