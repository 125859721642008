import { createSlice } from "@reduxjs/toolkit";
import { TCarSlice } from "../../../types/TCarSlice";
import { GET_CURRENT_DATE } from "../../../SupportingFiles/HelpingFunction";
import { CAR_QUOTE_REDUCERS } from "./CarQuoteReducer";
import { CAR_REDUCERS } from "./CarReducer";

const intitialState: TCarSlice = {
  REDIRECTED: false,
  SORT_BY: "Low to high",
  ALL_QUOTES_DATA: [],
  SELECTED_QUOTE_DATA: {
    buy_online_code: "",
    company_code: "",
    id: 0,
    loader: false,
    logo: "",
    product_code: "",
    product_type: "Comprehensive",
    quotationDetail: {
      premiumDetails: {
        correlationId: "",
        includedPersonalBelonging: 0,
        personalBelonging: 0,
        passerngerCover: 0,
        error: false,
        freeRoadSideAssistance: 0,
        tyreSecureCover: 0,
        fg_plan_type: "",
        tariffDiscount: 0,
        exshowroomPrice: 0,
        minimumIDV: 0,
        maximumIDV: 0,
        IDV: 0,
        basicOD: 0,
        basicTP: 0,
        netPremium: 0,
        ncbDiscount: 0,
        odDiscount: 0,
        antiTheftDiscount: 0,
        associationDiscount: 0,
        ePolicyDiscount: 0,
        otherDiscount: 0,
        deductibleDiscount: 0,
        ambulanceChargesCover: 0,
        consumablesCover: 0,
        depreciationCover: 0,
        hospitalCashCover: 0,
        hydrostaticLockCover: 0,
        keyReplacementCover: 0,
        paOwnerDriver: 0,
        engineGearBoxProtection: 0,
        noClaimBonusSameSlab: 0,
        roadSideAssistance: 0,
        medicalExpenses: 0,
        accessoryCoverCharge: 0,
        llDriver: 0,
        paFamily: 0,
        invoicePriceCover: 0,
        driverCover: 0,
        seatingCapacity: 0,
        includedPAOwnerDriver: 0,
        cngKitOD: 0,
        cngKitTP: 0,
        GD_ProPlanPremium: 0,
        GD_DProPlanPremium: 0,
        GD_DCProPlanPremium: 0,
        GD_DCEProPlanPremium: 0,
        GD_DCTProPlanPremium: 0,
        GD_DCETProPlanPremium: 0,
        GD_DCRTIProPlanPremium: 0,
        GD_DCERTIProPlanPremium: 0,
        GD_DCTRTIProPlanPremium: 0,
        GD_DCETRTIProPlanPremium: 0,
        GD_ProPlan: {
          personalbelonging: 0,
          roadside: 0,
          Key: 0,
        },
        GD_DProPlan: {
          personalbelonging: 0,
          roadside: 0,
          Key: 0,
          zerodeps: 0,
        },
        GD_DCProPlan: {
          personalbelonging: 0,
          roadside: 0,
          Key: 0,
          zerodeps: 0,
          consumable: 0,
        },
        GD_DCEProPlan: {
          personalbelonging: 0,
          roadside: 0,
          Key: 0,
          zerodeps: 0,
          consumable: 0,
          engine: 0,
        },
        GD_DCERTIProPlan: {
          personalbelonging: 0,
          roadside: 0,
          Key: 0,
          zerodeps: 0,
          consumable: 0,
          engine: 0,
          invoice: 0,
        },
        GD_DCTProPlan: {
          personalbelonging: 0,
          roadside: 0,
          Key: 0,
          zerodeps: 0,
          consumable: 0,
          tyre: 0,
        },
        GD_DCTRTIProPlan: {
          personalbelonging: 0,
          roadside: 0,
          Key: 0,
          zerodeps: 0,
          consumable: 0,
          tyre: 0,
          invoice: 0,
        },
        GD_DCETProPlan: {
          personalbelonging: 0,
          roadside: 0,
          Key: 0,
          zerodeps: 0,
          consumable: 0,
          engine: 0,
          tyre: 0,
        },
        GD_DCETRTIProPlan: {
          personalbelonging: 0,
          roadside: 0,
          Key: 0,
          zerodeps: 0,
          consumable: 0,
          engine: 0,
          tyre: 0,
          invoice: 0,
        },
        GD_DCRTIProPlan: {
          personalbelonging: 0,
          roadside: 0,
          Key: 0,
          zerodeps: 0,
          consumable: 0,
          invoice: 0,
        },
      },
      CompanyDetails: {
        company_code: "",
        name: "",
        short_desc: "",
        logo: "",
        claim_ratio: "",
      },
      productDetails: {
        id: 0,
        product_name: "",
        brochure: "",
        product_code: "",
        rate_calc_method: "",
        proposal_method: "",
        tax_calc_method: "",
        discount_calc_method: "",
        rating: 3,
        product_desc: "",
        buy_online_code: "",
        policy_covers: "",
        company_product_map: {
          company_code: "",
        },
      },
      cashlessGarageCount: 0,
    },
  },
  DROPDOWN_DATA: {
    POLICY_TYPE: [
      { label: "Comprehensive", value: "Comprehensive" },
      { label: "Third Party", value: "Third Party" },
    ],
    CLAIM_STATUS: [
      { label: "Yes", value: "Yes" },
      { label: "No", value: "No" },
    ],
    NCB: [
      { label: "0%", value: "0" },
      { label: "20%", value: "20" },
      { label: "25%", value: "25" },
      { label: "35%", value: "35" },
      { label: "45%", value: "45" },
      { label: "50%", value: "50" },
    ],
    POPULAR_MAKE_LIST: [
      { value: "maruti", label: "Maruti" },
      { value: "hyundai", label: "Hyundai" },
      { value: "tata", label: "Tata" },
      { value: "toyota", label: "Toyota" },
      { value: "kia", label: "Kia" },
      { value: "honda", label: "Honda" },
      { value: "MG", label: "MG" },
      { value: "mahindra", label: "Mahindra" },
      { value: "skoda", label: "Skoda" },
      { value: "BMW", label: "BMW" },
      { value: "mercedes", label: "Mercedes" },
      { value: "audi", label: "Audi" },
      { value: "volkswagen", label: "Volkswagen" },
      { value: "renault", label: "Renault" },
      { value: "ford", label: "Ford" },
    ],
    SORT_BY: [
      { label: "LOW_TO_HIGH", value: "Low to high" },
      { label: "HIGH_TO_LOW", value: "High to low" },
    ],
    MAKE_MODEL_LIST: [],
    PASSENGER_COVER_DATA: [
      { value: "50000", label: "₹ 50,000" },
      { value: "60000", label: "₹ 60,000" },
      { value: "70000", label: "₹ 70,000" },
      { value: "80000", label: "₹ 80,000" },
      { value: "90000", label: "₹ 90,000" },
      { value: "100000", label: "₹ 1,00,000" },
      { value: "110000", label: "₹ 1,10,000" },
      { value: "120000", label: "₹ 1,20,000" },
      { value: "130000", label: "₹ 1,30,000" },
      { value: "140000", label: "₹ 1,40,000" },
      { value: "150000", label: "₹ 1,50,000" },
      { value: "160000", label: "₹ 1,60,000" },
      { value: "170000", label: "₹ 1,70,000" },
      { value: "180000", label: "₹ 1,80,000" },
      { value: "190000", label: "₹ 1,90,000" },
      { value: "200000", label: "₹ 2,00,000" },
    ],
    POPULAR_MAKE: [
      "Hyundai",
      "Maruti",
      "Volkswagen",
      "Tata",
      "Mahindra",
      "Honda",
      "Kia",
      "Skoda",
      "Toyota",
      "Audi",
      "BMW",
      "Mercedes",
    ],
    RTO_LIST: [],
    FUEL_TYPE_LIST: [],
    MAKE_LIST: [],
    MODEL_LIST: [],
    PREVIOUS_INSURER_LIST: [],
    VARIANT_LIST: [],
    PLAN_TYPE: [
      { label: "Comprehensive", value: "Comprehensive" },
      { label: "Third Party", value: "Third Party" },
    ],
    GENDER_DATA: [
      { label: "Male", value: "Male" },
      { label: "Female", value: "Female" },
    ],
    RELATIONSHIP_DATA: [
      { label: "Father", value: "Father" },
      { label: "Mother", value: "Mother" },
      { label: "Brother", value: "Brother" },
      { label: "Sister", value: "Sister" },
      { label: "Grand Father", value: "Grand Father" },
      { label: "Grand Mother", value: "Grand Mother" },
      { label: "Brother In Law", value: "Brother In Law" },
      { label: "Mother In Law", value: "Mother In Law" },
      { label: "Daughter", value: "Daughter" },
      { label: "Son", value: "Son" },
      { label: "Wife", value: "Wife" },
    ],
    PA_OWNER_COVER_DATA: [
      {
        label: "Owner does not have valid driving license",
        value: "Owner does not have valid driving license",
      },
      {
        label: "Already having PA/SCPA cover",
        value: "Already having PA/SCPA cover",
      },
    ],

    OWNER_MARTIAL_STATUS_DATA: [
      { label: "Married", value: "Married" },
      { label: "Single", value: "Single" },
      { label: "Divorced", value: "Divorced" },
      { label: "Widow", value: "Widow" },
      { label: "Widower", value: "Widower" },
    ],
    VEHICLE_ON_LOAN: [],
  },
  ADD_FORM: {
    PAGE_STATUS: 1,
    term_cond: true,
    personalBelonging: 0,
    new_ncb: { value: "", warning: false },
    name: { value: "", warning: false },
    business_type: "Rollover",
    invoice_date: {
      value: GET_CURRENT_DATE(),
      warning: false,
    },
    claimed: "No",
    make_model: { value: "", warning: false },
    fuel_type: { value: "", warning: false },
    make: { value: "", warning: false },
    mobile: { value: "", warning: false },
    email: { value: "", warning: false },
    model: { value: "", warning: false },
    prev_ncb: "0",
    previous_insurer: { value: "", warning: false },
    reg_date: { value: null, warning: false },
    reg_no: { value: "", warning: false },
    variant_cc: { value: "", warning: false },
    rto: { value: "", warning: false },
    policy_expiry_date: { value: null, warning: false },
    automobile_association: "0",
    cng_kit: "",
    consumables_cover: 0,
    depreciation_cover: 0,
    driver_cover: 0,
    electric_accessory: "",
    engine_gear_box_protection: 0,
    invoice_cover: 0,
    key_replacement_cover: 0,
    no_claim_bonus_same_slab: 0,
    non_electric_accessory: "",
    pa_owner_driver: 0,
    passernger_cover: 0,
    road_side_assistance: 0,
    tyre_secure_cover: 0,
  },
  DISCOUNT_FORM: {
    anti_theft_device: "No",
    deductible: "0",
    vehicle_owned: "Individual",
  },
  ADD_FORM_RESPONSE: {
    business_type: "",
    car_master_data_id: 0,
    claim_made: "No",
    cubic_capacity: "",
    fuel_type: "",
    insurance_type: "",
    make: "",
    make_model: "",
    manufacture_date: "",
    model: "",
    new_ncb: "0",
    new_policy_date: "",
    policy_expiry_date: "",
    previous_insurer: "",
    previous_ncb: "0",
    quote_no: "",
    registration_date: "",
    registration_no: "",
    rto_code: "",
    seating_capacity: "",
    tp_policy_start_date: "",
    tp_previous_insurer: "",
    variant: "",
    variant_cc: "",
    idv: "",
    actual_reg_no: "",
  },
  IDV_SELECTED_OPTION: "Best Deal",
  PLAN_TYPE: "",
  PAGE_STATUS: false,
  QUOTE_LOADER: true,
  ADDON_STATUS: {
    depreciation_cover: { label: "Zero Depreciation", value: false },
    pa_owner_cover: { label: "Personal Accident Cover", value: false },
    personalBelonging: {
      label: "Personal Belonging",
      value: false,
    },
    rsa: { label: "24x7 Roadside Assistance", value: false },
    driver_cover: { label: "Driver Cover", value: false },
    consumable_cover: { label: "Consumable Cover", value: false },
    engine_cover: { label: "Engine Cover", value: false },
    invoice_cover: { label: "Invoice Cover", value: false },
    key_replacement: { label: "Key Replacement", value: false },
    ncb_cover: { label: "NCB Cover", value: false },
    tyre_cover: { label: "Tyre Cover", value: false },
    passenger_cover: { label: "Passenger Cover (₹)", value: false },
    electrical: { label: "Electrical (₹)", value: false },
    non_electrical: { label: "Non-Electrical (₹)", value: false },
    cng_kit: { label: "CNG Kit (₹)", value: false },
  },
  ADDON_USER_VALUE: {
    idv: { value: "", warning: false },
    cngKitValue: { value: "", warning: false },
    electricalAcessories: { value: "", warning: false },
    nonElectricalAcessories: { value: "", warning: false },
    passengerCover: { value: "", warning: false },
  },
  OWNER_DETAILS: {
    name: { value: "", warning: false },
    mobile: { value: "", warning: false },
    email: { value: "", warning: false },
    dob: { value: "", warning: false },
    gender: { value: "", warning: false },
    maritalStatus: { value: "", warning: false },
    pacover: {
      value: "Owner does not have valid driving license",
      warning: false,
    },
    validPuc: true,
    vehicleRegistered: false,
    isPolicyComprehensive: true,
    includePAOwner: false,
    gst: { value: "", warning: false },
  },
  CKYC_DETAILS: {
    propFullName: "",
    ProposerDob: "",
    docID: "",
    cusdtomertype: "",
    verificationDoc: "",
    gender: "",
  },
  NOMINEE_DETAILS: {
    fullName: { value: "", warning: false },
    dob: { value: "", warning: false },
    relationship: { value: "", warning: false },
  },
  ADDRESS_DETAILS: {
    pincode: { value: "", warning: false },
    city: "",
    state: "",
    addressLineOne: { value: "", warning: false },
    addressLineTwo: { value: "", warning: false },
    isMailingAddressSame: true,
    mailingPinCode: { value: "", warning: false },
    mailingCity: "",
    mailingState: "",
    mailingAddressLineOne: { value: "", warning: false },
    mailingAddressLineTwo: { value: "", warning: false },
  },
  VEHICLE_DETAILS: {
    chasis_no: { value: "", warning: false },
    engine_no: { value: "", warning: false },
    pan_no: { value: "", warning: false },
    bank_name: { value: "", warning: false },
    city: { value: "", warning: false },
    mfg_year: { value: "", warning: false },
    policy_exp_date: { value: "", warning: false },
    prev_insurer: { value: "", warning: false },
    prev_policy_no: { value: "", warning: false },
    reg_date: { value: "", warning: false },
    tp_policy: { value: "", warning: false },
    tp_insurer: { value: "", warning: false },
    tp_policy_start: { value: "", warning: false },
    is_vehicle_on_loan: false,
  },

  CAR_PREFILLED_PAGE_STATUS: 0,
  OPEN_CAR_PREFILLED_DATA: false,
  OPEN_CAR_FORM: false,
};

export const CarSlice = createSlice({
  name: "CAR",
  initialState: intitialState,
  reducers: { ...CAR_REDUCERS, ...CAR_QUOTE_REDUCERS },
});
