"use client"
import ExitIntentPopup from '@/Component/Desktop/Common/ExitIntentPopup/ExitIntentPopup';
import React, { useEffect, useState } from 'react'

const ExitIntent = () => {
    const [openCancellationPopup, setOpenCancellationPopup] = useState(false);
  const [timerElapsed, setTimerElapsed] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState<string | null>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      const data = sessionStorage.getItem("popupShown");
      setIsFormSubmitted(data);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  // Timer to delay the mouse movement detection
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimerElapsed(true);
    }, 30000); // 30 seconds

    return () => clearTimeout(timer);
  }, []);

  // Show the popup after 30 seconds if conditions are met
  useEffect(() => {
    if (
      isFormSubmitted ||
      sessionStorage.getItem("popupShown") ||
      sessionStorage.getItem("exitPopupShown") ||
      sessionStorage.getItem("leavePage")
    )
      return;

    const interval = setInterval(() => {
      if (
        !sessionStorage.getItem("popupShown") &&
        !sessionStorage.getItem("exitPopupShown") &&
        !sessionStorage.getItem("leavePage")
      ) {
        setOpenCancellationPopup(true);
        sessionStorage.setItem("popupShown", "true");
      }
    }, 30000);

    const storageCheckInterval = setInterval(() => {
      if (
        sessionStorage.getItem("popupShown") ||
        sessionStorage.getItem("exitPopupShown") ||
        sessionStorage.getItem("leavePage")
      ) {
        clearInterval(interval);
        clearInterval(storageCheckInterval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
      clearInterval(storageCheckInterval);
    };
  }, [isFormSubmitted]);

  useEffect(() => {
    if (!timerElapsed) {
      console.log("Early return: Timer not elapsed");
      return;
    }

    let mouseLeft = false;

    const handleMouseMove = (event: any) => {
      // Check sessionStorage inside the event handler
      if (
        sessionStorage.getItem("mouseMoved") ||
        sessionStorage.getItem("exitPopupShown") ||
        sessionStorage.getItem("leavePage")
      ) {
        console.log("Early return: Session storage condition met");
        window.removeEventListener("mousemove", handleMouseMove);
        return;
      }

      if (mouseLeft || openCancellationPopup) return;

      if (event.clientY <= 20) {
        mouseLeft = true;
        setOpenCancellationPopup(true);
        sessionStorage.setItem("mouseMoved", "true");
        console.log("Mouse moved to top: Popup shown");
        window.removeEventListener("mousemove", handleMouseMove);
      }
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [openCancellationPopup, isFormSubmitted, timerElapsed]);

  const handleCloseExitPopup = () => {
    setOpenCancellationPopup(false);
  };
  return (
    <div>
        {openCancellationPopup && (
        <ExitIntentPopup
          open={openCancellationPopup}
          setOpen={handleCloseExitPopup}
        />
      )}
    </div>
  )
}

export default ExitIntent
